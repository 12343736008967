import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.min.css';

import GLightbox from 'glightbox'
import 'glightbox/dist/css/glightbox.min.css';

import '../css/main.css';

new Swiper('.swiper-container', {
    // Optional parameters
    // direction: 'vertical',
    // loop: true,

    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 15,

    // If we need pagination
    pagination: {
        el: '.swiper-pagination',
    },
});

GLightbox();
